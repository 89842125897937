import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Main from "./components/Main";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Cases from "./components/Cases";
import AboutUs from "./components/AboutUs";
import PoliticaDePrivacidade from "./components/PoliticaDePrivacidade";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Router>
            <Header/>
            <main>
                <Routes>
                    <Route index path="/" element={<Main/>}/>
                    <Route caseSensitive={false} path="/aboutUs" element={<AboutUs/>}/>
                    <Route caseSensitive={false} path="/cases" element={<Cases/>}/>
                    <Route caseSensitive={false} path="/politicaDePrivacidade" element={<PoliticaDePrivacidade/>}/>
                </Routes>
            </main>
            <Footer/>
        </Router>
    </React.StrictMode>
);
