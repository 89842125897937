export default function PoliticaDePrivacidade() {
    return (
        <div className="container">
            <h1 className="fw-bolder">
                POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS
            </h1>
            <p>
                <h5 className="fw-bolder">1. Objetivo</h5>
                A presente Política de Privacidade e Proteção de Dados contém informações sobre a coleta, uso, armazenamento, tratamento e proteção dos dados pessoais de todos usuários dos sistemas Ironbug, e foi elaborado em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18) e o Marco Civil da Internet (Lei 12.965/14).
                Entendemos que todos valorizam e se preocupam com a sua privacidade e proteção de seus dados pessoais. Por esse motivo, elaboramos a presente Política de Privacidade que possui como objetivo principal informá-los sobre a coleta, uso, compartilhamento e a forma geral de tratamento de seus dados pessoais, sejam em meios digitais ou físicos, a fim de trazer uma melhor transparência sobre como e com quais finalidades seus dados são utilizados pela empresa.
                O nosso objetivo é adotar medidas de segurança e privacidade, de maneira transparente no tratamento e proteção de dados pessoais, visando conformidade com a Lei Geral de Proteção de Dados Pessoais e dando visibilidade a nossos usuários acerca do tratamento de seus dados, protegendo-os e atendendo os princípios das legislações vigentes.
                Assim, para garantir a privacidade e a proteção dos seus dados pessoais, é muito importante que você conheça e respeite as diretrizes da Política de Privacidade e Proteção de Dados Pessoais do Ironbug.
            </p>

            <p>
                <h5 className="fw-bolder">2. Princípios norteadores desta Política</h5>
                São os princípios norteadores desta política, em consonância com a Lei Geral de Proteção de Dados:

                <ul>
                    <li>Adequação: o tratamento dos dados tem que ser compatível com a finalidade informada ao titular.</li>
                    <li>Necessidade: o tratamento deve ser limitado ao mínimo necessário para atingir a finalidade proposta.</li>
                    <li>Livre acesso: os titulares têm o direito de acessar a qualquer tempo as informações referentes ao tratamento que seus dados recebem.</li>
                    <li>Qualidade dos dados: o tratamento dos dados deve mantê-los exatos, claros, relevantes e atualizados, sem discrepâncias ou distorções.</li>
                    <li>Transparência: o tratamento dos dados deve ser explicado aos titulares de maneira transparente e acessível, observado o segredo comercial e industrial necessário.</li>
                    <li>Segurança: os dados pessoais devem ser protegidos pelo controlador, para que não sejam perdidos, alterados, destruídos ou acessados indevidamente.</li>
                    <li>Prevenção: cabe ao controlador tomar medidas para prevenir danos provenientes do tratamento de dados pessoais.</li>
                    <li>Não discriminação: o tratamento de dados pessoais não deve ser realizado com finalidades discriminatórias, ilícitas ou abusivas.</li>
                    <li>Responsabilização e prestação de contas: demonstração, aos titulares, das medidas utilizadas para garantir conformidade com a Lei Geral de Proteção de Dados Pessoais.</li>
                </ul>

            </p>

            <p>
                <h5 className="fw-bolder">3. Dados pessoais que podemos coletar e a sua utilização</h5>
                As informações referentes a pessoas físicas somente devem ser coletadas na medida da necessidade para a prestação de serviços, e em todas as hipóteses cabíveis o consentimento para o tratamento dos dados deverá ser obtido em conformidade com a Lei Geral de Proteção de Dados.
                Os dados pessoais também podem ser obtidos diretamente pelo Ironbug por meio de interações e por meio de seus produtos e serviços, sendo que os dados pessoais coletados online também podem ser combinados com os dados pessoais fornecidos por meio de canais offline, como, por exemplo, durante uma reunião, eventos realizados e ainda, de forma automática, relacionadas ao uso de nossos sites e resposta aos nossos e-mails por meio do uso de várias tecnologias.
                Os dados poderão ser usados para a identificação e autenticação do usuário quando do acesso aos serviços do Ironbug, no processamento de informações pessoais a fim de cumprir com as obrigações contratuais firmadas respectivamente com colaboradores, terceiros, parceiros e clientes, sempre visando garantir a segurança dos dados pessoais.
                O Ironbug jamais utilizará os dados pessoais a ela confiados de maneira diversa com o propósito de sua obtenção, salvo mediante prévia autorização e consentimento do titular dos dados.
            </p>

            <p>
                <h5 className="fw-bolder">4. Do consentimento</h5>
                É a partir do consentimento que tratamos os dados pessoais. O consentimento é a manifestação livre, informada e inequívoca pela qual o titular autoriza o Ironbug a tratar seus dados.
                Assim, em consonância com a Lei Geral de Proteção de Dados, os dados só serão coletados, tratados e armazenados mediante prévio e expresso consentimento.
                O consentimento é requerido ao solicitar os dados aos clientes, parceiros e colaboradores que forem pessoas físicas – quando necessário – através do aceite no campo apropriado do sistema, ou do aceite ao e-mail resposta com o qual a solicitação dos serviços for concluída, na fase comercial. O consentimento é requerido ao solicitar assinatura de termo apropriado quando da contratação de novos empregados, estagiários e prestadores de serviços.
                O consentimento será de forma específica para cada finalidade descrita, evidenciando o compromisso de transparência e boa-fé do Ironbug para seus usuários, clientes, colaboradores, parceiros e funcionários, os quais poderão inclusive, a qualquer tempo, revogá-lo.
            </p>

            <p>
                <h5 className="fw-bolder">5. Responsabilidade compartilhada</h5>
                A responsabilidade pelo correto tratamento dos dados pessoais dentro da empresa é compartilhada entre todos aqueles que atuam como operadores, sendo fundamental a cooperação de todos para que a empresa esteja sempre em conformidade com a lei, oferecendo segurança a todos os titulares de dados pessoais sob seu controle.
                O Ironbug poderá divulgar dados pessoais com parceiros de negócios e provedores de serviços para apoiar nossas operações. Tais parceiros de negócios e provedores de serviços são obrigados por contrato a manter as informações recebidas confidenciais e seguras e não usá-las para qualquer propósito diferente daquele para o qual foram fornecidos a eles.
                O compartilhamento de dados pessoais com pessoas ou entidades externas do Ironbug deve ser restrito ao mínimo necessário para a execução dos contratos e prestações de serviços nos quais os titulares estão envolvidos, ou para o cumprimento de qualquer obrigação legal. Mesmo quando o tratamento envolver diretamente a prestação de serviços, o consentimento para este tratamento e compartilhamento deverá ter sido previamente obtido.
                O Ironbug, pode ainda, divulgar dados pessoais conforme exigido por Lei ou processo legal, e que sejam imprescindíveis para o cumprimento de determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos, os quais serão mantidas, a despeito da exclusão dos demais dados.
                Nos termos dos art. 42 e seguintes da Lei Geral de Proteção de Dados (Lei 13.709 de 14 de agosto de 2018), o operador de dados pessoais que descumprir as diretrizes lícitas de proteção de dados do controlador – no caso o Ironbug – responderá como se também fosse controlador dos dados em questão, estando assim sujeito à responsabilidade cível, administrativa e criminal sobre o tratamento inadequado dos dados.
                É vedado o compartilhamento externo de dados pessoais de clientes ou membros da empresa – por qualquer meio, telefônico, digital ou por escrito – sem autorização destes, sendo dada a ciência devida ao titular sempre que os dados forem compartilhados em um novo contexto, não previstos no consentimento recolhido. A violação de segredos da empresa, concepção que inclui dados pessoais sob seu controle, poderá ser motivo para embasar a demissão por justa causa de colaboradores ou a rescisão de contrato de prestadores de serviços envolvidos na violação, sem prejuízo das ações de regresso cabíveis judicialmente.
            </p>

            <p>
                <h5 className="fw-bolder">6. Tratamento dos dados pessoais</h5>
                O tratamento dos dados pessoais no Ironbug deve seguir os princípios definidos nessa política, devendo ser estritamente voltado às finalidades às quais a coleta dos dados se destina, respeitando os princípios desta política e os critérios de compartilhamento e de segurança das informações e a legislação pertinente.
                Os dados pessoais deverão ser manipulados apenas por pessoas que precisem lidar com eles. Assim, reduzem-se os riscos de falhas humanas propiciarem um vazamento ou uso inadequado da informação. A melhor forma de garantir isso é dividindo os dados por setores, e por responsabilidades específicas dentro de cada setor. Assim se saberá em cada situação quem são os operadores dos dados, e os riscos de um incidente na segurança da informação diminuirão enormemente.
                Para garantir este tratamento setorizado dos dados, o acesso de cada empregado ou prestador de serviço do Ironbug ao banco de dados da empresa é individual e protegido por senha própria e intransferível. Assim, somente as pessoas autorizadas a manipular dados pessoais identificáveis de funcionários e contratados, por exemplo, poderão acessá-los.
                O único tratamento admitido para dados pessoais contidos nos resíduos eletrônicos recebidos e gerenciados pelo Ironbug é a eliminação. Para garantir que nenhum dos dados que eventualmente estejam armazenados nos dispositivos que recebe para gerenciamento sejam utilizados indevidamente, todos são destruídos no processamento dos materiais, com a devida certificação aos clientes dos quais estes dados provêm. O acesso dos empregados e prestadores de serviço aos materiais e às informações acerca deles contidas nos sistemas informatizados é restrito à previsão da Ordem de Serviço e à função determinada para cada colaborador.
                O mero acesso e/ou a utilização indevida de quaisquer dados pessoais armazenados nos resíduos tecnológicos processados pela empresa são terminantemente proibidos, sob pena de demissão por justa causa (ou rescisão do contrato de prestação de serviços) sem prejuízo da responsabilização cível e criminal cabível em âmbito judiciário.
            </p>

            <p>
                <h5 className="fw-bolder">7. Período em que os dados serão armazenados</h5>
                Os dados pessoais coletados pelo Ironbug serão utilizados e armazenados durante o tempo necessário para a prestação do serviço ou para que as finalidades elencadas na presente Política de Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores. Assim, os dados serão mantidos enquanto a relação contratual entre o titular e o Ironbug perdurar e, findo o período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no artigo 16 LGPD, a saber:

                <ol type="I">
                    <li>Cumprimento de obrigação legal ou regulatória pelo controlador;</li>
                    <li>Estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li>
                    <li>Transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei; ou</li>
                    <li>Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</li>
                </ol>
                Assim, quando atingida à finalidade do tratamento dos dados pessoais, e eles não mais precisarem ser armazenados para satisfazer quaisquer exigências legais, estes deverão, ressalvadas as hipóteses do parágrafo anterior, ser devidamente eliminados física e digitalmente, com a comunicação desta eliminação ao titular nos casos em que ela se dê de maneira diversa àquela prevista no termo de consentimento aplicável. Portanto, o Ironbug firma o compromisso com a segurança e privacidade dos dados pessoais coletados, mediante o emprego de medidas e soluções técnicas de proteção aptas a garantir a confidencialidade, integridade e inviolabilidade dos dados, contanto ainda, com medidas de segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.
            </p>

            <p>
                <h5 className="fw-bolder">8. Das medidas de segurança e dos critérios de armazenagem dos dados</h5>
                Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas para a proteção da sua privacidade. Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.
                O Ironbug se compromete a adotar as melhores posturas para evitar incidentes de segurança.
                Dentre as medidas adotadas, destacamos as seguintes:
                Apenas pessoas autorizadas têm acesso aos dados pessoais;
                O acesso aos dados pessoais é feito somente após o compromisso de confidencialidade;
                Os dados pessoais armazenados fisicamente ficarão em local protegido por tranca, fora do alcance de outras pessoas que não as expressamente autorizadas a acessá-los.
                Quando armazenados digitalmente, devem ficar em pasta protegida por criptografia e restrição de acesso por senha pessoal.
                Eventuais cópias de dados pessoais somente devem ser feitas em caso de necessidade para cumprimento da finalidade proposta ao tratamento, e todas as cópias feitas devem ser registradas em planilha própria, que deve ser guardada digitalmente com os mesmos critérios de segurança.
            </p>

            <p>
                <h5 className="fw-bolder">9. Mudanças na Política de Privacidade</h5>
                Esta Política de Privacidade pode passar por atualizações. Desta forma, recomendamos a vista periódica desta página para conhecimento sobre as eventuais alterações. Contudo, o Ironbug, antes de usar suas informações para outros fins que não os definidos nesta Política de Privacidade, solicitará seu consentimento.
            </p>
        </div>
    );
}